<template>
  <Outer>
    <div class="b-auth">
      <h2 class="b-title _center">
        <template v-if="username">Welcome, {{username}}!</template>
        <template v-else>Welcome!</template>
      </h2>

      <p class="b-text _note _center">
        Choose language
      </p>

      <form class="b-form _code" @submit.prevent="apiProfileUpdate">
        <div class="b-row _space_above">
          <div class="b-input _select">
            <div
              class="b-select"
              :class="{ _expanded: selectExpanded }"
              @click="toggle_select"
            >
              <div
                class="b-select_current"
                :class="{ _expanded: selectExpanded }"
              >
                <span
                  class="b-icon"
                  :class="`_lng_${currentLanguage.code}`"
                ></span>
                <span>{{ currentLanguage.name }}</span>
              </div>

              <div v-if="selectExpanded" class="b-select__options">
                <div
                  v-for="(item, index) of languagesArray"
                  :key="item.code"
                  class="b-select__option"
                  :index="index"
                  @click="setLanguage(item.code)"
                >
                  <span class="b-icon" :class="`_lng_${item.code}`"></span>
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="b-row _space_above">
          <button class="b-button _full-width" type="submit">
            {{ translate('auth---Choose') }}
          </button>
        </div>

        <div v-if="submitError" class="b-row _error">
          <code>{{ submitError }}</code>
        </div>
      </form>
    </div>
  </Outer>
</template>

<script>
import Outer from '../components/Outer.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { debounce } from 'throttle-debounce';

export default {
  components: {
    Outer,
  },
  data() {
    return {
      selectExpanded: false,
    };
  },
  computed: {
    ...mapState({
      step: (state) => state.step,
      username: (state) => state.username,
      errors: (state) => state.errors,
      submitError: (state) => state.submitError,
    }),
    ...mapGetters(['languagesArray', 'currentLanguage']),
  },
  methods: {
    ...mapActions(['setLanguage', 'apiProfileUpdate']),
    toggleSelect: debounce(100, function () {
      this.selectExpanded = !this.selectExpanded;
    }),
    setLanguage(language) {
      this.setLanguage(language);
    },
  },
};
</script>
